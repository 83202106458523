<template>
  <v-app class="transparent">
    <v-container id="regular-tables" fluid tag="section">
      <base-v-component :heading="'Failed Rows for ' + sheetName"/>
      <base-material-card
        color="error darken-2"
        icon="mdi-alert-circle"
        title="Failed Rows"
        class="px-5 py-3">
        <v-progress-linear
          v-if="loading"
          indeterminate
          color="error darken-2"
        ></v-progress-linear>
        <v-data-table
          :headers="headers"
          :items="failedRows"
          :expanded.sync="expanded"
          item-key="id"
          show-expand
          hide-default-footer
          disable-pagination
          group-by="row"
          show-group-by
          class="elevation-0">
          <template v-slot:top>
            <v-toolbar flat>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" v-for="error in item.errors" class="error--text font-weight-bold">
              {{ error }}
            </td>
          </template>
        </v-data-table>
      </base-material-card>
      <div class="text-center">
        <v-pagination
          color="error darken-2"
          v-if="pagination.total > 10"
          v-model="pagination.page"
          :length="pagination.length"
          :total-visible="7"
          @input="getFailedRows"
          @next="getFailedRows"
          @previous="getFailedRows"/>
      </div>
    </v-container>
  </v-app>
</template>

<script>
  export default {
    name: "FailedRows",
    data() {
      return {
        loading: false,
        failedRows: [],
        sheetName: '',
        expanded: [],
        singleExpand: false,
        headers: [
          { text: 'Row #', value: 'row' , align: 'left'},
          { text: 'Column Name', value: 'attribute', align: 'center' },
          { text: 'Created At', value: 'created_at', align: 'right', groupable: false },
        ],
        pagination: {
          page: 1,
          length: 0,
          total: 0
        },
      }
    },
    created() {
      this.getFailedRows();
    },
    methods: {
      getFailedRows() {
        this.loading = true;
        this.$authApi.get(`user-sheets/${this.$route.params.id}/failed-sheet-rows?page=${this.pagination.page}`).then(res => {
          this.failedRows = res.data.data.data.data;
          this.sheetName = res.data.data.sheet_name;
          this.pagination.length = res.data.data.data.last_page;
          this.pagination.total = res.data.data.data.total;
          this.loading = false;
        }).catch(err => {
          this.loading = false;
        })
      }
    }
  }
</script>

<style>
  .theme--light.v-data-table thead tr:last-child th {
    font-size: 14px !important;
    font-weight: bold !important;
  }
</style>
